import React  from "react";

import RoutesTree from "./components/RoutesTree";

function App() {
  return (
    <div>
      <RoutesTree />
    </div>  
  );
}

export default App;