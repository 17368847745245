export const skills = [
  "Java",
  "Spring",
  "Python",
  "MySQL",
  "MongoDB",
  "AWS",
  "Apache Spark",
  "Apache Kafka",
];
